var ONE = 1;

/**
 * Iterates through the properties of an object and applies a callback function.
 *
 * @param {Object} obj - The input object to be iterated.
 * @param {Function} cb - The callback function to be applied to each property's value and key.
 * @param {boolean} [sorted=false] - Flag indicating whether to sort keys before iteration.
 * @returns {Array} - Returns an array of values returned by the callback function for each property.
 */
var loopObject = function loopObject(obj, cb, sorted) {
    var keys = Object.keys(obj);
    if (sorted === true) {
        keys.sort();
    }
    return keys.map(function (key) {
        return cb(obj[key], key);
    });
};

/**
 * Generates a sorted key string representation of an object.
 *
 * @param {Object} obj - The input object.
 * @returns {string} - Returns a comma-separated string of sorted keys.
 */
var getSortedKeyString = function getSortedKeyString(obj) {
    return Object.keys(obj)
        .sort()
        .join(',');
};

/**
 * Gets the type of a value.
 *
 * @param {*} val - The value to determine the type of.
 * @returns {string} - Returns the type of the value as a string.
 */
var getType = function (val) {
    return Object.prototype.toString.call(val).replace(/^\[object\s(.*)\]$/, '$1');
};

/**
 * Gets the value of the first element in an object.
 *
 * @param {Object} obj - The input object.
 * @returns {*} - Returns the value of the first element in the object.
 */
var getFirstEle = function getFirstEle(obj) {
    return obj[Object.keys(obj)[0]];
};

/**
 * Checks if all values in an array are the same.
 *
 * @param {Array} arr - The input array to be checked.
 * @returns {boolean} - Returns true if all values in the array are the same, otherwise returns false.
 */
var allValuesSameInArray = function (arr) {
    for (var i = 1; i < arr.length; i++) {
        if (arr[i] !== arr[0]) {
            return false;
        }
    }
    return true;
};

/**
 * Checks if the given object contains an Array of Objects (AOB).
 *
 * An Array of Objects (AOB) is an array where each element is an Object.
 * This function determines whether all the Objects in the array share the same set of keys.
 *
 * @param {Array} obj - The object to be checked.
 * @returns {boolean} - Returns true if the object contains an Array of Objects (AOB)
 *                      with all Objects sharing the same keys, otherwise returns false.
 */
var checkIfArrayIsAOB = function checkIfArrayIsAOB(obj) {
    if (getType(obj) === 'Array' && obj.length > ONE && getType(getFirstEle(obj)) === 'Object') {
        var test = loopObject(obj, function (row) {
            if (getType(row) === 'Object') {
                return getSortedKeyString(row);
            } else {
                return '';
            }
        });
        if (test.length > ONE && test[0].length > ONE) {
            return allValuesSameInArray(test);
        } else {
            return false;
        }
    } else {
        return false;
    }
};

/**
 * Checks if the given object is an Object of Objects (OOB).
 *
 * An Object of Objects (OOB) is an object where each value is an Object.
 * This function determines whether all the Objects in the object's values share the same set of keys.
 *
 * @param {Object} obj - The input object to be checked.
 * @returns {boolean} - Returns true if the input object is an Object of Objects (OOB)
 *                     with all Objects sharing the same keys, otherwise returns false.
 */
var checkIfObjectIsOOB = function checkIfObjectIsOOB(obj) {
    if (getType(obj) === 'Object' && Object.keys(obj).length > ONE && getType(getFirstEle(obj)) === 'Object') {
        var test = loopObject(obj, function (row) {
            if (getType(row) === 'Object') {
                return getSortedKeyString(row);
            } else {
                return '';
            }
        });
        if (test.length > ONE && test[0].length > ONE) {
            return allValuesSameInArray(test);
        } else {
            return false;
        }
    } else {
        return false;
    }
};

module.exports = {
    loopObject,
    getSortedKeyString,
    getType,
    getFirstEle,
    allValuesSameInArray,
    checkIfArrayIsAOB,
    checkIfObjectIsOOB,
};