import React, { useState, useEffect } from 'react';
import {Button, Link, Table} from "@fluentui/react-components";
import moment from 'moment';

function relativeTime(timeString) {
    const currentTime = moment();
    const providedTime = moment(timeString);
    const timeDifference = currentTime.diff(providedTime, 'hours');

    return `${timeDifference} hours ago`;
}

function GetMwcReleaseUri(releaseId) {
    return `https://dev.azure.com/powerbi/MWC/_releaseProgress?_a=release-pipeline-progress&releaseId=${releaseId}`
}

function GetPbiSharedBuildUri(buildId) {
    return `https://dev.azure.com/powerbi/Power%20BI/_build/results?buildId=${buildId}&view=results`
}

function HistoryList(props) {
    const history = props.history || [];
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <Button onClick={handleClick}>{isExpanded ? 'Hide' : 'Show'} History</Button>
            {isExpanded && (
                <ul>
                    {history.map((entry) => (            
                        <li key={entry}><Link href={GetMwcReleaseUri(entry)}>{entry}</Link></li>

                    ))}
                </ul>
            )}
        </>
    );
}

function TableRow(props) {
    const entry = props.entry;
    return (
        <tr key={entry.id}>
            <td>{entry.id}</td>
            <td><Link href={GetMwcReleaseUri(entry.originalReleaseId)}>{entry.originalReleaseId}</Link></td>
            <td>{entry.mwcRolloutName}</td>
            <td><Link href={`https://app-f${entry.pbiSharedBackendRolloutName}.analysis.windows-int.net`}>{entry.pbiSharedBackendRolloutName}</Link></td>
            <td>{entry.startedProvisioningAt} ({relativeTime(entry.startedProvisioningAt)})</td>
            <td><Link href={GetMwcReleaseUri(entry.checkedOutBy)}>{entry.checkedOutBy}</Link></td>
            <td><HistoryList history={entry.history} /></td>
            <td><Link href={`https://dev.azure.com/powerbi/MWC/_build?definitionId=7634&keywordFilter=${entry.mwcVersion}`}>{entry.mwcVersion}</Link></td>
            <td><Link href={GetPbiSharedBuildUri(entry.sharedBuildId)}>{entry.sharedBuildId}</Link></td>
            <td>{entry.markedForDeletion ? "True" : ""}</td>
        </tr>
    );
}

function CstPoolSet(props) {
    const entries = props.entries;
    return (
        <Table>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Original Release Id</th>
                    <th>CST Slot</th>
                    <th>Shared Rollout</th>
                    <th>Provisioned At</th>
                    <th>Checked Out By</th>
                    <th>Past Checkouts</th>
                    <th>Mwc Version</th>
                    <th>PBI Shared Build Id</th>
                    <th>Expire On Checkin</th>
                </tr>
            </thead>
            <tbody>
                {entries.map(entry => <TableRow entry={entry} />)}
            </tbody>
        </Table>
    );
}

export default CstPoolSet;