import {
    Button,
    Popover,
    PopoverTrigger,
    PopoverSurface,
    Badge,
    Checkbox,
    Tab,
    TabList,
    Field,
    Textarea
} from "@fluentui/react-components";

import { Delete16Filled, Info16Filled } from "@fluentui/react-icons";
import { useState } from "react";
export const CallParameters = ({ params, setParams, reqBody, setReqBody }) => {

    const [currentParamTab, setCurrentParamTab] = useState("params")

    function addParam(key = "", val = "") {
        var paramDummy = [...params]
        paramDummy.push({ "key": key, "val": val, "description": "", "type": "text" })
        setParams(paramDummy)
        return (0);
    }

    function deleteParam(key) {
        var paramDummy = [...params]
        const halfBeforeDelete = paramDummy.slice(0, key)
        const halfAfterDelete = paramDummy.slice(key + 1)
        paramDummy = halfBeforeDelete.concat(halfAfterDelete)
        for (let i = key; i < paramDummy.length; i++) {
            document.getElementById('inputKey' + i).value = paramDummy[i].key
            document.getElementById('inputVal' + i).value = paramDummy[i].val
        }
        setParams(paramDummy)
    }

    function editParam(index, type, val) {
        var paramDummy = [...params]
        if (type === "key") {
            paramDummy[index].key = val;
        }
        else {
            paramDummy[index].val = val;
        }
        setParams(paramDummy)
    }

    return (
        <>
            <TabList defaultSelectedValue={currentParamTab}>
                <Tab value="params" onClick={() => setCurrentParamTab("params")}>Params {Object.keys(params).length !== 0 ? <Badge size="tiny" /> : null}</Tab>
                <Tab value="body" onClick={() => setCurrentParamTab("body")}>Body  {reqBody ? <Badge size="tiny" /> : null}</Tab>
            </TabList>
            {currentParamTab === "params" ?
                (<>
                    {params.map((param, index) => (
                        <div key={"wrap" + index} id={index} autoComplete="off" className="ms-Grid-col ms-lg12">
                            <div>
                                <Checkbox checked />
                                <span style={{ width: "39%" }} class="fui-Input r1jtohuq ___1v9icnz_137yv9i fvcxoqz f1ub3y4t f1l4zc64 f1m52nbi f8vnjqi fz1etlk f1klwx88 f1hc16gm">
                                    <input type="text" autoComplete="off" class="fui-Input__input rvp2gzh" placeholder="Key" onChange={(ev) => editParam(index, "key", ev.target.value)} id={`inputKey${index}`} defaultValue={param.key} />
                                </span>
                                <span style={{ width: "39%" }} class="fui-Input r1jtohuq ___1v9icnz_137yv9i fvcxoqz f1ub3y4t f1l4zc64 f1m52nbi f8vnjqi fz1etlk f1klwx88 f1hc16gm">
                                    <input type={param.type} autoComplete="off" class="fui-Input__input rvp2gzh" placeholder="Value" onChange={(ev) => editParam(index, "val", ev.target.value)} id={`inputVal${index}`} defaultValue={param.val} />
                                </span>
                                <Button appearance="transparent" style={{ marginBottom: 5 }} icon={<Delete16Filled />} onClick={() => deleteParam(index)} />
                                {param.description !== "" ? (
                                    <Popover withArrow openOnHover mouseLeaveDelay={0} positioning={"after"}>
                                        <PopoverTrigger disableButtonEnhancement>
                                            <Button appearance="transparent" style={{ marginBottom: 5 }} icon={<Info16Filled />} />
                                        </PopoverTrigger>

                                        <PopoverSurface>
                                            <span>{param.description}</span>
                                        </PopoverSurface>
                                    </Popover>) : null}

                            </div>
                        </div>
                    ))
                    }
                    <div className="ms-Grid-row" style={{ marginInline: 40 }}>
                        <Button onClick={() => addParam()}>Add param</Button>
                    </div>
                </>)
                :
                <div autoComplete="off" className="ms-Grid-col ms-lg12">
                    <Field>
                        <Textarea resize="vertical" size="large" value={reqBody} onChange={(ev) => setReqBody(ev.target.value)} placeholder="Only applicable for request methods 'PUT', 'POST', 'DELETE', and 'PATCH'"></Textarea>
                    </Field>
                </div>
            }
        </>
    )
}