import { SignInBtn } from "../components/SignInBtn";
import { Avatar } from "@fluentui/react-components";
import { SearchBox } from "@fluentui/react-search-preview";
import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { useLayoutEffect, useState } from "react";
import { SignOutBtn } from "./SignOutBtn";
import Axios from 'axios';
export const Header = ({ setFilter }) => {
    const isAuthenthicated = useIsAuthenticated();
    const { instance } = useMsal();
    const [username, setUsername] = useState('');
    const [imageUrl, setImageUrl] = useState(null)
    useLayoutEffect(() => {
        const currentAccount = instance.getActiveAccount();
        if (currentAccount) {
            setUsername(currentAccount.name);
            async function fetchGraphToken() {
                const token = await instance.acquireTokenSilent({
                    scopes: ["User.Read"]
                });
                return token;
            }
            fetchGraphToken().then(res => {
                Axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
                    headers: { Authorization: `Bearer ${res.accessToken}` },
                    responseType: "blob"
                }).then(o => {
                    const url = window.URL || window.webkitURL;
                    const blobUrl = url.createObjectURL(o.data);
                    setImageUrl(blobUrl)
                })
            })
        }
    }, [isAuthenthicated]);
    return (
        <div style={{ marginBlock: 15, marginInline: 10, width: "100%" }} class="ms-Grid" >
            {isAuthenthicated ? <div class="ms-Grid-row"  >
                <div role="search" class="ms-Grid-col ms-lg4" style={{ display: "flex", marginBlock: -5, marginInline: 10, alignItems: "center" }}>
                    <SearchBox onChange={(ev) => setFilter(ev.target.value.toLowerCase())} placeholder="Search Function" aria-label="Search Function" />
                </div>
                <div style={{ display: "flex", marginBlock: -5, marginInline: 10, justifyContent: "flex-end", alignItems: "center" }}>
                    Hi, {username}! <Avatar style={{ marginLeft: 10 }} aria-label="Guest" image={{ src: imageUrl }} />
                    <SignOutBtn />
                </div>
            </div> :
                <div style={{ display: "flex", marginInline: 10, justifyContent: "flex-end", alignItems: "center" }}>
                    <SignInBtn style={{ display: "inline-block", marginInline: 10 }} />
                </div>}
        </div>
    );
}