import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useEffect, useLayoutEffect, useState } from "react";
import Axios from 'axios';
import { Button} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
export const Home = (props) => {
    const isAuthenthicated = useIsAuthenticated();
    const { instance } = useMsal();
    const [functionList, setFunctionList] = useState([]);
    const [filteredFL, setFilteredFL] = useState([]);
    const navigate = useNavigate();

    useLayoutEffect(() => {
        async function fetchToken() {
            const token = await instance.acquireTokenSilent({
                scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/user_impersonation`]
            });
            return token;
        }
        if (isAuthenthicated) {
            fetchToken().then(resToken => {
                Axios.get(process.env.REACT_APP_ENDPOINT_URL + '/api/GetFunctions', {
                    headers: { Authorization: `Bearer ${resToken.accessToken}` }
                }).then(res => {
                    setFunctionList(res.data.value)
                    setFilteredFL(res.data.value)
                }).catch(res => {
                    console.log("There was an error retriving the functions.")
                })

            })
        }
    }, [isAuthenthicated]);

    useEffect(() => {
        var funcListDummy = []
        functionList.filter(func => {
            if (func.properties.name.toLowerCase().includes(props.filter)) {
                funcListDummy.push(func)
            }
        })
        setFilteredFL(funcListDummy);
    }, [props.filter])

    function fetchScheme(functionName) {
        props.setFuncURI(process.env.REACT_APP_ENDPOINT_URL + "/api/" + functionName);
        async function fetchToken() {
            const token = await instance.acquireTokenSilent({
                scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/user_impersonation`]
            });
            return token;
        }
        fetchToken().then(resToken => {
            Axios.get(process.env.REACT_APP_ENDPOINT_URL + '/api/UiScheme', {
                headers: { Authorization: `Bearer ${resToken.accessToken}` },
                params: { "FunctionName": functionName }
            }).then(res => {
                console.log("scheme found")
                props.setUiScheme(res.data)
                navigate("/" + res.data.pageStyle)
            }).catch(res => {
                console.log("no scheme found")
                props.setUiScheme(null)
                navigate("/call");
            })

        })
    }

    return (
        <div>
            <AuthenticatedTemplate>
                <h1>Welcome to EzFunc!</h1>
                <h3>Functions:</h3>
                {filteredFL
                    .filter((azFunction) => azFunction.properties.config.bindings[0].authLevel != null && azFunction.properties.config.bindings[0].authLevel !== "function")
                    .map((azFunction) => (
                    <div style={{ display: "inline-block" }}>
                        <Button appearance="primary" size="large" style={{ margin: 10 }} onClick={() => { fetchScheme(azFunction["properties"]["name"]) }}>{azFunction["properties"]["name"]}</Button>
                    </div>
                ))}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>Please sign-in to see available functions.</p>
            </UnauthenticatedTemplate>
        </div>
    );
}