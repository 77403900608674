import { useMsal } from "@azure/msal-react";
import { Button } from "@fluentui/react-components";

export const SignOutBtn = () => {
    const { instance } = useMsal();
    const handleSignOut = () => {
        instance.logoutRedirect();
    }

    return (
        <Button style={{ display: "inline-block", marginInline: 10 }} onClick={handleSignOut} size="small" appearance="primary" shape="circular">Sign Out</Button>
    )
}