import { useMsal } from "@azure/msal-react";
import { Button } from "@fluentui/react-components";

export const SignInBtn = () => {
    const { instance } = useMsal();
    const handleSignIn = () => {
        instance.loginRedirect({
            scopes: ['User.Read']
        })
    }

    return (
        <Button onClick={handleSignIn} size="small" appearance="primary" shape="circular">Sign In</Button>
    )
}