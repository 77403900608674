import { Home } from './pages/Home';
import './App.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { Sidenav } from './components/Sidenav';
import { Header } from './components/Header';
import { useEffect, useState } from 'react';
import { Call } from './pages/Call';
import { Return404 } from './pages/Return404';
import { CancelBuildPipelines } from './pages/CancelBuildPipelines';
import SelectPool from './pages/SelectPool';
import GetSpecificPool from './pages/GetSpecificPool';

function App({ msalInstance }) {
  const [filter, setFilter] = useState("");
  return (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <div className="grid-container">
          <header className="header"><Header setFilter={setFilter} /></header>
          <aside className="sidenav">
            <Sidenav />
          </aside>
          <div className="main">
            <Pages filter={filter} />
          </div>
        </div>
      </BrowserRouter>
    </MsalProvider>
  );
}

const Pages = (props) => {
  const [funcURI, setFuncURI] = useState("");
  const [uiScheme, setUiScheme] = useState(null);
  useEffect(() => {
    console.log(funcURI)
  }, [funcURI])
  return (
    <Routes>
      <Route path="/" element={<Home filter={props.filter} setFuncURI={setFuncURI} setUiScheme={setUiScheme} />} />
      <Route path="/call" element={<Call funcURI={funcURI} uiScheme={uiScheme} />} />
      <Route path="/pools" element={<SelectPool />} />
      <Route path="/pools/*" element={<GetSpecificPool />} />
      <Route path="/cancelBuildPipelines" element={<CancelBuildPipelines />} />
      <Route path="*" element={<Return404 />} />
    </Routes>
  );
}

export default App;
