import {
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
    Input,
} from "@fluentui/react-components";
export const CallHeader = (props) => {
    const methods = ["GET", "POST", "PUT", "PATCH", "DELETE"]
    return (
        <div className="ms-Grid" style={{ display: "flex", marginBottom: 20 }}>
            <div className="ms-Grid-row" style={{ width: "100%" }}>
                <div className="ms-Grid-col ms-lg1" style={{ display: "flex", alignItems: "center", width: "auto" }}>

                    <Menu appearance="primary">
                        <MenuTrigger disableButtonEnhancement>
                            <Button  >{props.currentMethod}</Button>
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                {methods.map((method) => (
                                    <MenuItem onClick={() => { props.setCurrentMethod(method); }} disabled={props.currentMethod === { method } ? true : false}>{method}</MenuItem>
                                ))}
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </div>
                <div className="ms-Grid-col ms-lg8" style={{ display: "flex", alignItems: "center" }}>
                    <Input style={{ width: "100%" }} value={props.funcURI} />
                </div>
                <div className="ms-Grid-col ms-lg1" style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "auto" }}>
                    <Button appearance="primary" onClick={() => props.displayResponse()}>Send</Button>
                </div>
            </div>
        </div>
    )
}