import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { Spinner , Button} from "@fluentui/react-components";
import CstPoolSet from '../components/CstPoolSet';
import { useNavigate } from "react-router-dom";


function SpecificPool() {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const isAuthenthicated = useIsAuthenticated();
    const [poolData, setPoolData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    async function fetchToken() {
        const token = await instance.acquireTokenSilent({
            scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/user_impersonation`]
        });
        return token;
    }
    useEffect(() => {
        async function fetchPoolData() {
            setIsLoading(true);
            try {
                const resToken = await fetchToken();
                const poolName = window.location.pathname.split('/')[2];
                const apiPath = process.env.REACT_APP_ENDPOINT_URL + '/api/GetCstPoolEntries?poolName=' + poolName;
                const response = await axios.get(apiPath,
                    {
                        headers:
                        {
                            Authorization: `Bearer ${resToken.accessToken}`
                        }
                    });
                const deletingEntryIds = response.data.pools[0].deletingEntries.map(entry => entry.id);
                const checkedOutEntries = response.data.pools[0].checkedOutEntries.map(entry => {
                    if (deletingEntryIds.includes(entry.id)) {
                        return { ...entry, markedForDeletion: true };
                    }
                    return entry;
                });
                setPoolData({
                    descriptor: response.data.pools[0].descriptor,
                    provisioningEntries: response.data.pools[0].provisioningEntries,
                    checkedInEntries: response.data.pools[0].checkedInEntries,
                    checkedOutEntries: checkedOutEntries,
                });
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }

        if (isAuthenthicated) {
            fetchPoolData();
        }
    }, [isAuthenthicated]);

    if (!isAuthenthicated) {
        return (
            <div>You must authenticate to view this page</div>
        );
    }
    if (isLoading) {
        return (
            <Spinner>Loading...</Spinner>
        );
    }
    return (
        <div>
            <Button onClick={() => { navigate("/pools") }}>Back</Button>
            <h1> Provisioning</h1>
            <CstPoolSet entries={poolData.provisioningEntries} />
            <h1> Checked In</h1>
            <CstPoolSet entries={poolData.checkedInEntries} />
            <h1> Checked Out</h1>
            <CstPoolSet entries={poolData.checkedOutEntries} />
        </div>
    );
}

export default SpecificPool;