import { Button } from "@fluentui/react-components";
import { Link } from "react-router-dom";

export const Return404 = () => {
    return (
        <div className="main">
            <h1>404 Page not found</h1>
            <Link to="/"><Button>Return to Home</Button></Link>
        </div>
    );
}