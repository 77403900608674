import {
    Button,
    Divider,
    MenuList,
} from "@fluentui/react-components";
import '../App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import {
    CalendarMonthRegular
} from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
export const Sidenav = () => {
    const navigate = useNavigate();
    return (
        <div className="ms-Grid" dir="ltr">
            <h1>EzFunc</h1>
            <AuthenticatedTemplate>
                <div className="ms-Grid-row" style={{ textAlign: "center" }}>
                    <div className="ms-Grid-col ms-lg12 sidenavBtn">
                        <MenuList>
                            <Button appearance="primary" icon={<CalendarMonthRegular />} onClick={() => { navigate("/") }} size="large">
                                Functions
                            </Button>
                            <Divider appearance="brand" alignContent="center" style={{ fontWeight: "bold" }}>Function Pages</Divider>
                            <Button appearance="secondary" onClick={() => { navigate("/pools") }} size="large">
                                View CST Pools
                            </Button>
                            <Button appearance="secondary" onClick={() => { navigate("/cancelBuildPipelines") }} size="large">
                                Cancel Builds
                            </Button>
                        </MenuList>
                    </div>
                </div>
            </AuthenticatedTemplate>
        </div>
    )
}