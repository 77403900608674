import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { getType } from './util';

class ValueViewer extends Component {

    render() {
        const valueType = {
            String: <span className='key'>{`"${this.props.value}"`}</span>,
            Boolean: <span className='boolean'>{`${this.props.value}`}</span>,
            Number: <span className='number'>{`${this.props.value}`}</span>,
            Undefined: <i className='null'>{'undefined'}</i>,
            Null: <i className='null'>{'null'}</i>,
            Date: <i className='number'>{`${JSON.stringify(this.props.value)}`}</i>
        }
        return <span>{valueType[getType(this.props.value)] || ("<span className='key'>{" + `${this.props.value}` + "}</span>;")}</span>;
    }
}
ValueViewer.propTypes = {
    value: PropTypes.any,
};
ValueViewer.defaultProps = {};
export default ValueViewer;