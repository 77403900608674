import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from 'axios';
import {
    Button,
    Spinner,
    Table,
    Divider,
} from "@fluentui/react-components";
import { SearchBox } from "@fluentui/react-search-preview";
import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { useNavigate } from "react-router-dom";


function SelectPool() {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const isAuthenthicated = useIsAuthenticated();
    const listAllPoolsUri = process.env.REACT_APP_ENDPOINT_URL + '/api/GetCstPoolEntries?includeElements=false';
    const [pools, setPools] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredPools, setFilteredPools] = useState([]);

    async function fetchToken() {
        const token = await instance.acquireTokenSilent({
            scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/user_impersonation`]
        });
        return token;
    }

    const handleSearch = event => {
        const searchString = event.target.value;

        setFilteredPools(pools.filter(pool => pool.descriptor.poolName.includes(searchString)))
    };


    useEffect(() => {

        if (isAuthenthicated) {
            async function fetchPools() {
                setIsLoading(true);
                try {
                    const resToken = await fetchToken();
                    const response = await axios.get(listAllPoolsUri, { headers: { Authorization: `Bearer ${resToken.accessToken}` } });
                    setPools(response.data.pools);
                    setFilteredPools(response.data.pools);
                } catch (error) {
                    console.log(error);
                }
                setIsLoading(false);
            }
            fetchPools();
        }
    }, [isAuthenthicated]);
    if (!isAuthenthicated) {
        return (
            <div>You must authenticate to view this page</div>
        );
    }
    if (isLoading) {
        return (
            <Spinner>Loading...</Spinner>
        );
    }

    return (
        <div>
            <Divider appearance="brand" alignContent="start" style={{ fontWeight: "bold" }}>Pools</Divider>
            <SearchBox placeholder="Search Pools" onChange={handleSearch}></SearchBox>
            <Table>
                {filteredPools.map(pool => (
                    <Button appearance="secondary" className="button-pool" onClick={() => { navigate("/pools/" + pool.descriptor.poolName) }} size="large">
                        {pool.descriptor.poolName}
                    </Button>
                ))}
            </Table>
        </div>
    );
}

export default SelectPool;