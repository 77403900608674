import { useEffect, useState } from "react";
import { CallHeader } from "../components/CallHeader";
import { useMsal } from "@azure/msal-react"
import {
    Divider,
    Toaster,
    useToastController,
    Toast,
    ToastTitle,
    ToastBody
} from "@fluentui/react-components";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { ResponseDisplay } from "../components/ResponseDisplay";
import { CallParameters } from "../components/CallParameters";
export const Call = ({ funcURI, uiScheme }) => {
    const { instance } = useMsal();
    const [currentMethod, setCurrentMethod] = useState("GET")
    const [params, setParams] = useState([])
    const [currentURI, setCurrentURI] = useState(funcURI)
    const [funcRes, setFuncRes] = useState("")
    const [reqBody, setReqBody] = useState("")
    const { dispatchToast } = useToastController("toaster");

    const navigate = useNavigate();

    useEffect(() => {
        if (currentURI === "") {
            navigate('/')
        }

        var paramDummy = [...params]
        if (uiScheme) {
            if (uiScheme.method) {
                setCurrentMethod(uiScheme.method);
            }
            for (const param of uiScheme.parameters) {
                paramDummy.push({ "key": param.name, "val": param.value, description: param.description, "type": param.type })
            }
        }
        else {
            paramDummy.push({ "key": "", "val": "", "description": "", "type": "text" })
        }

        setParams(paramDummy)
    }, [])

    useEffect(() => {
        var URI = funcURI
        if (params[0]?.key !== "" || params[0]?.val !== "") {
            URI = URI + '?'
            let concat = false
            params.forEach((param) => {
                let newParam
                concat ? newParam = "&" + param.key + "=" + param.val : newParam = param.key + "=" + param.val
                URI = URI + newParam
                concat = true
            })
            setCurrentURI(URI)
        }
        else setCurrentURI(funcURI)
    }, [params])

    function isJSON(text) {
        if (typeof text !== "string") {
            return false;
        }
        try {
            JSON.parse(text);
            return true;
        } catch (error) {
            return false;
        }
    }

    function displayResponse() {
        async function fetchToken() {
            const token = await instance.acquireTokenSilent({
                scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/user_impersonation`]
            });
            return token;
        }
        let body = isJSON(reqBody) ? JSON.parse(reqBody) : ({ reqBody });
        fetchToken().then(resToken => {
            Axios(currentURI, {
                headers: { Authorization: `Bearer ${resToken.accessToken}` },
                method: currentMethod.toLowerCase(),
                data: body,
            }).then(res => {
                setFuncRes(res)
                notify("success", res.status, res.statusText)
            }).catch(res => {
                notify("error", res.code, res.message)
            })
        })
    }

    function notify(type, code, message) {
        dispatchToast(
            <Toast>
                <ToastTitle>{message}</ToastTitle>
                <ToastBody>Code: {code}</ToastBody>
            </Toast>,
            { intent: type }
        );
    }

    return (
        <div style={{ overflow: "hidden" }}>
            <div>
            </div>
            <div className="ms-Grid" style={{ padding: 15, height: "100%" }}>
                <div className="ms-Grid-row" style={{ height: "40%", overflow: "auto" }}>
                    {uiScheme ? <h1 style={{ padding: 0, margin: 5, marginBottom: 20 }}>{uiScheme.functionName}: {uiScheme.description}</h1> : null}
                    <CallHeader displayResponse={displayResponse} currentMethod={currentMethod} setCurrentMethod={setCurrentMethod} funcURI={currentURI} />
                    <div className="ms-Grid-row" style={{ width: "100%" }}>
                        <CallParameters params={params} setParams={setParams} reqBody={reqBody} setReqBody={setReqBody} />
                    </div>
                </div>
                <Divider appearance="brand" alignContent="start" style={{ fontWeight: "bold" }}>Response</Divider>
                <ResponseDisplay response={funcRes.data} allowedOutputTypes={uiScheme?.allowedOutputTypes} />
                <Toaster toasterId={"toaster"} />
            </div>
        </div>
    )
}