import { useState } from "react";
import JsonTableParser from "../components/JsonTableParser/JsonTableParser";
import {
    Tab, TabList,
} from "@fluentui/react-components";
export const ResponseDisplay = ({ response, allowedOutputTypes }) => {

    // Parses a JSON string and matches different value types to give proper styles to them
    // then it replaces the matches with span tags with styling and appends it on a pre tag.
    function syntaxHighlight(json = "") {
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        var pre = document.createElement('pre');
        pre.id = "preJson"
        document.getElementById('jsonWrapper').appendChild(pre).innerHTML =
            json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                var cls = 'number';
                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }
                return '<span class="' + cls + '">' + match + '</span>';
            });
    }

    const [currentTab, setCurrentTab] = useState(!allowedOutputTypes ? "json" : allowedOutputTypes[0])
    return (
        <div className="ms-Grid-row" id="jsonWrapper" style={{ height: "55%", overflow: "auto" }}>
            {response ? (
                <div>
                    <TabList defaultSelectedValue={currentTab}>
                        {!allowedOutputTypes || allowedOutputTypes?.includes("json") ? <Tab value="json" onClick={() => { setCurrentTab("json"); }}>Pretty</Tab> : null}
                        {!allowedOutputTypes || allowedOutputTypes?.includes("table") ? <Tab value="table" onClick={() => setCurrentTab("table")}>Table</Tab> : null}
                        {!allowedOutputTypes || allowedOutputTypes?.includes("raw") ? <Tab value="raw" onClick={() => setCurrentTab("raw")}>Raw</Tab> : null}
                    </TabList>
                    {currentTab === "table" ? <JsonTableParser json={response} /> : document.getElementById('preJson')?.remove()}
                    {currentTab === "raw" ? <div>{JSON.stringify(response)}</div> : document.getElementById('preJson')?.remove()}
                    {currentTab === "json" ? syntaxHighlight(JSON.stringify(response, null, 2)) : document.getElementById('preJson')?.remove()}
                </div>) : <div></div>
            }
        </div>
    )
}