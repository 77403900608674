import { useState } from "react";
import { useMsal } from "@azure/msal-react"
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useFormik } from 'formik';
import {
    Divider,
    Toaster,
    useToastController,
    makeStyles,
    shorthands,
    Toast,
    ToastTitle,
    Textarea,
    Button,
    Input,
    Label,
    ToastBody,
    Card,
    MenuList,
} from "@fluentui/react-components";
import Axios from "axios";
import { ResponseDisplay } from "../components/ResponseDisplay";
const useStyles = makeStyles({
    root: {
        // Stack the label above the field
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("5px"),
    },
});
export const CancelBuildPipelines = () => {
    const { instance } = useMsal();
    const functionUri = process.env.REACT_APP_ENDPOINT_URL + '/api/cancelAllBuilds';
    const { dispatchToast } = useToastController("toaster");

    const [funcRes, setFuncRes] = useState("")
    function notify(type, code, message) {
        dispatchToast(
            <Toast>
                <ToastTitle>{message}</ToastTitle>
                <ToastBody>Code: {code}</ToastBody>
            </Toast>,
            { intent: type }
        );
    }
    // todo: not sure these styles are actually working
    const styles = useStyles();
    async function fetchToken() {
        const token = await instance.acquireTokenSilent({
            scopes: [`api://${process.env.REACT_APP_CLIENT_ID}/user_impersonation`]
        });
        return token;
    }

    const formik = useFormik({
        initialValues: {
            NewestToKeep: 1,
            OldestToKeep: 1,
            DryRun: true,
            WaitAfterFirstCancellationSeconds: 30,
        },
        onSubmit: values => {
            let valuesClone = structuredClone(values);
            valuesClone.BuildsToCancel = valuesClone.BuildsToCancel?.split(",").map(val => val.trim());
            let body = JSON.stringify(valuesClone, null, 2);
            fetchToken().then(resToken => {
                notify("info", 0, "Sending request " + body);
                Axios(functionUri, {
                    headers: { Authorization: `Bearer ${resToken.accessToken}` },
                    method: "POST",
                    data: body,
                }).then(res => {
                    setFuncRes(res.data)
                    notify("success", res.status, res.statusText)
                }).catch(res => {
                    notify("error", res.code, res.message)
                })
            })
        },
    });

    return (
        <div className={styles.root}>
            <AuthenticatedTemplate>
                <Card className={styles.card}>
                    This function enables you to cancel Azure Devops Build Pipeline runs in the Powerbi/MWC project in bulk. Please use caution when invoking it. Specify the pipeline name (or comma separated list of names)
                    in the BuildsToCancel section. Set DryRun to false when you want to actually cancel runs. Typically you should try a run with DryRun true first to see how many it will cancel.
                </Card>
                <form onSubmit={formik.handleSubmit}>
                    <MenuList>
                        <br />
                        <Label htmlFor="BuildsToCancel">BuildsToCancel</Label>
                        <br />
                        <Textarea
                            id="BuildsToCancel"
                            name="BuildsToCancel"
                            type="text"
                            rows="5"
                            cols="33"
                            onChange={formik.handleChange}
                            value={formik.values.BuildsToCancel}
                            placeholder="Enter a comma separated list of build names (not definition IDs)"
                        />
                        <br />
                        <Label htmlFor="NewestToKeep">NewestToKeep</Label>
                        <br />
                        <Input
                            id="NewestToKeep"
                            name="NewestToKeep"
                            type="integer"
                            onChange={formik.handleChange}
                            value={formik.values.NewestToKeep}
                        />

                        <br />
                        <Label htmlFor="OldestToKeep">OldestToKeep</Label>
                        <br />
                        <Input
                            id="OldestToKeep"
                            name="OldestToKeep"
                            type="integer"
                            onChange={formik.handleChange}
                            value={formik.values.OldestToKeep}
                        />
                        <br />
                        <Label htmlFor="DryRun">DryRun</Label>
                        <br />
                        <Input
                            id="DryRun"
                            name="DryRun"
                            type="boolean"
                            onChange={formik.handleChange}
                            value={formik.values.DryRun}
                        />
                        <br />
                        <Label htmlFor="WaitAfterFirstCancellationSeconds">WaitAfterFirstCancellationSeconds</Label>
                        <br />
                        <Input
                            id="WaitAfterFirstCancellationSeconds"
                            name="WaitAfterFirstCancellationSeconds"
                            type="integer"
                            onChange={formik.handleChange}
                            value={formik.values.WaitAfterFirstCancellationSeconds}
                        />
                        <br />
                            <Button className={styles.button} type="submit">Submit</Button>
                    </MenuList>
                </form>
                <Divider appearance="brand" alignContent="start" style={{ fontWeight: "bold" }}>Response</Divider>
                <ResponseDisplay response={funcRes} />
                <Toaster toasterId={"toaster"} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>Please sign-in to see this page.</p>
            </UnauthenticatedTemplate>
        </div>
    );
};